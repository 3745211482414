export default {
    state: {
        authorityList: JSON.parse(window.sessionStorage.getItem('authorityList')) ? JSON.parse(window.sessionStorage.getItem('authorityList')) : [], // 用户权限列表
    },
    actions: {
    },
    getters: {
        getAuthority: state => state.authorityList, // 获取用户权限
        isHasPress: (state, getters) => (pressArr) => {
            const { authorityList } = state;
            if (pressArr && pressArr instanceof Array && authorityList && authorityList.length >= 0) {
                const authors = authorityList.map(item => item.keyword);
                const isHas = pressArr.some(item => authors.includes(item));
                return isHas;
            }
        }
    },
    mutations: {
        setAuthority(state, value) {
            state.authorityList = value; // 更新用户权限列表
        }
    }
}