import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './plugins/element.js'

//input限制
import inputValidate from 'vue-input-directive'
Vue.use(inputValidate)

//重写message(保证每次只弹窗一次)
import { message } from './plugins/resetMessage.js'
// Vue.use(ElementUI)
Vue.prototype.$message = message;


import VueParticles from 'vue-particles'
Vue.use(VueParticles)

//导入全局样式表
import './assets/css/global.css'
// 引入进度条样式
// import 'nprogress/nprogress.css'

//导入时间格式化moment.挂载原型$moment
import moment from 'moment'//导入文件
Vue.prototype.$moment = moment;//赋值使用


Vue.config.productionTip = false

Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 2000)//我这里设置的是2000毫秒也就是2秒
    })
  }
})
// 按钮权限指令
Vue.directive('hasPress', {
  inserted(el, binding, vnode) {
    const { value } = binding; // 获取指令的值，即所需的权限 []
    const authorityList = store.getters.getAuthority; // 从Vuex store中获取用户角色

    if (value && value instanceof Array && authorityList && authorityList.length >= 0) {
      const authors = authorityList.map(item => item.keyword);
      if (!value.some(item => authors.includes(item))) {
        el.parentNode && el.parentNode.removeChild(el); // 如果用户没有权限，则移除按钮元素
      }
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
